body {
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#bg-video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  /*width: auto;*/

  width: 100%;
  height: 100%;
  object-fit: cover;
}

footer {
  padding: 25px 0;
  text-align: center;
  color: #fff;
  background: none!important;
  position: absolute;
  bottom: 0;
  width: 100%;
}

footer a.nav-link  {
  color: #fff!important;
}

footer .copyright {
  float: right;
  margin-top: 5px;
}

footer .nav {
  display: inline-block;
  float: left;
}
.footer .nav-item {
  display: inline-block!important;
  text-transform: uppercase;
}


footer .copyright a.nav-link  {
  display:contents;
}

footer a:hover {
  color: #9f000c!important;
}

#responseWrapperView {
  padding:10px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media (max-width: 767.98px){
  .footer .nav {
    float: none;
  }

  .footer .copyright {
    text-align: center;
    float: none;
  }
}

/* Chrome, Safari, Edge, Opera */
.react-code-input input::-webkit-outer-spin-button,
.react-code-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.react-code-input input[type=number] {
  -moz-appearance: textfield;
}

body {
  height: 100%;
  background: #9f000c;
  background-image: none;
}

.btn-link,
a {
color: #9f000c!important;
}

.btn-primary {
background-color: #9f000c!important;
border-color: #9f000c!important;
}

.btn-outline-primary {
border-color: #9f000c!important;
color: #9f000c!important;
}

.btn-outline-primary:hover {
background-color: #9f000c!important;
color: #fff!important;
}